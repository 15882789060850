<template>
  <div class=''>
    <!-- probably should do something a bit different for contacts -->
    <div v-if='isViewMode'>
      {{field.display_value}}
    </div>
    <contact-search
      v-else
      :contact-id='selectedContactId'
      :contact-name='field.display_value'
      @update-selected-contact='updateSelectedContact'
      />
  </div>
</template>

<script>
import ContactSearch from '@/components/contacts/ContactSearch.vue'

export default {
  name: 'CustomFieldContact',
  props: ['field', 'isViewMode'],
  components: {
    ContactSearch,
  },
  data () {
    return {
      selectedContactId: 0
    }
  },
  watch: {
    'field.value_json': {
      handler: function (newVal) {
        if (newVal) {
          this.selectedContactId = newVal.map(id => parseInt(id))[0]
        }
      },
      immediate: true
    },
  },
  methods: {
    updateSelectedContact (newContact) {
      this.selectedContactId = parseInt(newContact.id)
      this.field.value = newContact.contact_name
      this.field.display_value = newContact.contact_name
      this.field.value_json = [parseInt(newContact.id)]
      this.$emit('update-value', this.field)
    },
  }
}
</script>
